import { stickyNoteColors } from ".";
const clickArea = document.querySelector("[data-click-area]");
const navBg = document.querySelectorAll("[data-nav-bg]");
const homeTop = document.querySelector(".c-home__top");
const homeBottom = document.querySelector(".c-home__bottom");

const getOffset = (element, scroll = 0) => {
  const box = element.getBoundingClientRect();

  return {
    bottom: box.bottom,
    height: box.height,
    left: box.left,
    top: box.top + scroll,
    width: box.width,
    y: box.y,
  };
};

const info = document.querySelector("[data-info]");
const works = document.querySelector("[data-works]");
const navInfo = document.querySelector("[data-nav-info]");
const navWorks = document.querySelector("[data-nav-works]");
const footer = document.querySelector(".c-home__footer");
const title = document.querySelector(".c-home__title");

const transitionHomeTop = () => {
  homeTop.style.opacity = 0;

  setTimeout(() => {
    homeTop.style.opacity = 1;
  }, 500);

  if (window.innerWidth <= 950) {
    homeBottom.style.opacity = 0;

    setTimeout(() => {
      homeBottom.style.opacity = 1;
    }, 500);
  }
};

navInfo.addEventListener("click", (e) => {
  if (e.target.classList.contains("active")) return;
  transitionHomeTop();

  setTimeout(() => {
    info.classList.remove("inactive");
    works.classList.add("inactive");

    navInfo.classList.add("active");
    navWorks.classList.remove("active");

    title.style.transform = `translateY(0)`;
    footer.classList.remove("active");
    clickArea.classList.remove("disabled");
  }, 500);
});

navWorks.addEventListener("click", (e) => {
  if (e.target.classList.contains("active")) return;
  transitionHomeTop();
  setTimeout(() => {
    info.classList.add("inactive");
    works.classList.remove("inactive");

    navInfo.classList.remove("active");
    navWorks.classList.add("active");

    const footerHeight = getOffset(footer).height;

    title.style.transform = `translateY(-${footerHeight + 24}px)`;
    footer.classList.add("active");

    clickArea.classList.add("disabled");
  }, 500);
});

// change navbg every 1.5 seconds to stickyNoteColors, no random
let index = 0;

navBg.forEach((bg) => {
  setInterval(() => {
    bg.style.opacity = 0;
    setTimeout(() => {
      bg.style.backgroundColor = `#${stickyNoteColors[index]}`;
      bg.style.opacity = 1;
    }, 300);
    index = (index + 1) % stickyNoteColors.length;
  }, 600);
});
